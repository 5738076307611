<template>
	<div>
		<el-tree v-loading="loading" ref="tree"  :data="data" show-checkbox node-key="id"  :props="{children: 'children',label: 'title'}"></el-tree>
		<div class="drawer-footer">
			<Button style="margin-right: 8px">重置</Button>
			<Button type="primary" @click="onSubmit">提交</Button>
		</div>
	</div>
</template>

<script>
	import {request} from "@/library/request.js";
	export default {
		data(){
			return{
				data:[],
				id:"",
				expanded:[],
				loading:false,
			}
		},
		methods:{
			onSubmit(){
				this.loading = true
				var formData = []
				var that = this
				formData = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys())
				return request({
					url:'system/role/jurisdiction/'+this.id,
					method:"post",
					params:formData
				}).then(response  => {
					this.loading = false;
					if(response.code==200){
						this.$Message.success({
							content:response.msg,
							duration:1.5,
							onClose:function(){	
								that.$emit('routerCancel', true)
							}
						})
					}else{
						this.$Message.error({
							content:response.msg,
							duration: 3
						})
					}
				}).then(err => {
					if(err){
						this.loading = false;
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
			},
			details(id){
				this.loading = true;
				return request({
					url:'system/role/jurisdiction/'+id,
					method:"get",
				}).then(response  => {
					this.loading = false;
					if(response.code!=200){
						this.$Message.error(response.msg)
					}else{
						this.data = response.data.menuInfo
						this.expanded = response.data.rule
						response.data.rule.forEach((id) => {
							this.$nextTick(() => {
								var node = this.$refs.tree.getNode(id);
								console.log(node.children)
								if (node.childNodes) {
									this.$refs.tree.setChecked(node, true);
								}
							});
						});
					}
				}).then(err => {
					if(err){
						this.loading = false;
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
			}
		},
		watch:{
			'$route.params': {
					handler(newVal,oldVal){
						this.id = newVal.id
						this.details(newVal.id)
					},
				immediate: true
			}
		}
	}
</script>

<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
